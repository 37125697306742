export const serialize = (o: Record<any, any>) => new URLSearchParams(o).toString();

export const pathWithQuery = (path: string, query?: Record<any, any>) =>
  [path, query && serialize(query)].filter(Boolean).join('?');

export const pathRootDevicesQuery = (path: string, query?: Record<any, any>) => {
  const parsedQuery = [];
  if (query) {
    const { organizations, ...rest } = query;

    parsedQuery.push(serialize(rest));

    if (organizations) {
      const composedOrganizations = `organizations=${organizations.join(',')}`
      parsedQuery.push(new URLSearchParams(composedOrganizations).toString())
    }
  }
  return [path, parsedQuery.join('&')].filter(Boolean).join('?')
}

export const pathInspectionGroupQuery = (path: string, query?: Record<any, any>) => {
  const parsedQuery = []
  if (query) {
    const { type, review, ...rest} = query

    parsedQuery.push(serialize(rest))

    if (type) {
      const composedType = `service.id[]=${type.join('&service.id[]=')}`
      parsedQuery.push(new URLSearchParams(composedType).toString())
    }

    if (review) {
      const composedReview = `review=${review.join(',')}`
      parsedQuery.push(new URLSearchParams(composedReview).toString())
    }
  }
  return [path, parsedQuery.join('&')].filter(Boolean).join('?')
}
