import { api } from '@/services';

import {
  CreateUserData,
  DeleteUserData,
  GetUserData, GetUserKeyFiguresData,
  PasswordUserData,
  ResetUserPasswordRequestData,
  UpdateUserData,
  User,
  UpdateUserPasswordData,
} from '@/entities/user/types';

import { QueryFilters, TokenCheckData, TokenCheckResponseData } from '@/types/services';
import { pathWithQuery } from '@/utils/string';
import { AxiosRequestConfig } from 'axios';

export const getCurrentUser = (): Promise<User> => api.get(`/me`);

export const searchUsers = (term: string): Promise<User[]> => api.get(`/user/search?query=${term}`);

export const getCurrentUserKeyFigures = (params: GetUserKeyFiguresData): Promise<User> => api.get('/key_figures', { params });

export const getUser = ({ id }: GetUserData): Promise<User> => api.get(`/users/${ id }`);

export const getUsers = (filters: QueryFilters<User>, axiosConfig: AxiosRequestConfig = {}): Promise<User[]> =>
  api.get(pathWithQuery('/users', filters), { ...axiosConfig });

export const createUser = (data: CreateUserData): Promise<User> => api.post('/users', data);

export const updateUser = (data: UpdateUserData): Promise<User> =>
  api.put(`/users/${ data.id }`, data);

export const deleteUser = (data: DeleteUserData): Promise<User> => api.delete(`/users/${ data.id }`);

export const activateInvitedUser = (data: PasswordUserData): Promise<string> =>
  api.put(`/invitation/activate/${ data.token }`, data);

export const checkInvitationUserToken = ({ token }: TokenCheckData): Promise<TokenCheckResponseData> =>
  api.get(`/invitation/check/${ token }`);

export const resetUserPasswordRequest = (data: ResetUserPasswordRequestData): Promise<string> =>
  api.post(`/reset-password`, data);

export const resetUserPassword = (data: PasswordUserData): Promise<string> =>
  api.put(`/reset-password/verify/${ data.token }`, data);

export const checkResetUserPasswordToken = ({ token }: TokenCheckData): Promise<TokenCheckResponseData> =>
  api.get(`/reset-password/check/${ token }`);

export const updateUserPassword = (data: UpdateUserPasswordData): Promise<User> =>
  api.put(`/me/change-password`, data);
