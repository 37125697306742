import { api } from '@/services';
import {
  CreateDeviceData,
  DeleteDeviceData,
  Device,
  GetDeviceData,
  UpdateDeviceData,
} from '@/entities/device/types';
import { QueryFilters } from '@/types/services';
import { pathRootDevicesQuery, pathWithQuery } from '@/utils/string';
import { AxiosRequestConfig } from 'axios';

export const getDevice = ({ id }: GetDeviceData): Promise<Device> => api.get(`/devices/${ id }`);

export const getDevices = (
  filters: QueryFilters<Device> | undefined = undefined,
  axiosRequestConfig?: AxiosRequestConfig | undefined,
): Promise<Device[]> => api.get(pathWithQuery('/devices', filters), { ...axiosRequestConfig });

export const getRootDevices = (
  filters: QueryFilters<Device> | undefined = undefined,
): Promise<Device[]> => api.get(pathRootDevicesQuery(`/devices/roots`, filters));

export const getNestedDevices = (data: GetDeviceData): Promise<Device[]> =>
  api.get(`/devices/${ data.id }/nested_devices`);

export const createDevice = (data: CreateDeviceData): Promise<Device> => api.post('/devices', data);

export const updateDevice = (data: UpdateDeviceData): Promise<Device> =>
  api.put(`/devices/${ data.id }`, data);

export const deleteDevice = (data: DeleteDeviceData): Promise<Device> =>
  api.delete(`/devices/${ data.id }`);

export const cloneDevice = (data: UpdateDeviceData): Promise<Device> =>
  api.post(`/devices/${ data.id }/clone`);

