import { shouldBeLoggedMiddleware } from '@/router/middlewares';
import { RouteRecordRaw } from 'vue-router';

import { NkPage } from '@/core/components';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/administration/',
    name: 'admin-dashboard',
    component: NkPage,
    meta: {
      layout: 'AdminLayout',
      entry: 'admin',
      middlewares: [shouldBeLoggedMiddleware],
    },
    props: () => ({
      name: 'admin-dashboard',
      component: () => import('@/teams/administration/views/NkDashboard.vue'),
    }),
  },
  {
    path: '/administration/organizations',
    name: 'admin-organizations',
    component: NkPage,
    meta: {
      layout: 'AdminLayout',
      entry: 'admin',
      middlewares: [shouldBeLoggedMiddleware],
    },
    props: () => ({
      name: 'admin-organizations',
      component: () => import('@/teams/administration/views/NkOrganizations.vue'),
    }),
    children: [
      {
        path: ':id',
        name: 'admin-organization',
        component: NkPage,
        meta: {
          layout: 'AdminLayout',
          entry: 'admin',
          middlewares: [shouldBeLoggedMiddleware],
        },
        props: () => ({
          name: 'admin-organization',
          component: () => import('@/teams/administration/views/NkOrganization.vue'),
        }),
        children: [
          {
            path: 'user/:id',
            name: 'admin-organisation-user',
            component: NkPage,
            meta: {
              layout: 'AdminLayout',
              entry: 'admin',
              middlewares: [shouldBeLoggedMiddleware],
            },
            props: () => ({
              name: 'admin-organisation-user',
              component: () => import('@/teams/administration/views/NkOrganizationUser.vue'),
            }),
          },
        ],
      },
    ],
  },
  {
    path: '/administration/users',
    name: 'admin-users',
    component: NkPage,
    meta: {
      layout: 'AdminLayout',
      entry: 'admin',
      middlewares: [shouldBeLoggedMiddleware],
    },
    props: () => ({
      name: 'admin-users',
      component: () => import('@/teams/administration/views/NkUsers.vue'),
    }),
  },
  {
    path: '/administration/profiles',
    name: 'admin-profiles',
    component: NkPage,
    meta: {
      layout: 'AdminLayout',
      entry: 'admin',
    },
    props: () => ({
      name: 'admin-profiles',
      component: () => import('@/teams/administration/views/NkProfiles.vue'),
    }),
  },
  {
    path: '/administration/severities',
    name: 'admin-severities',
    component: NkPage,
    meta: {
      layout: 'AdminLayout',
      entry: 'admin',
      middlewares: [shouldBeLoggedMiddleware],
    },
    props: () => ({
      name: 'admin-severities',
      component: () => import('@/teams/administration/views/NkSeverities.vue'),
    }),
  },
  {
    path: '/administration/weathers',
    name: 'admin-weathers',
    component: NkPage,
    meta: {
      layout: 'AdminLayout',
      entry: 'admin',
      middlewares: [shouldBeLoggedMiddleware],
    },
    props: () => ({
      name: 'admin-weathers',
      component: () => import('@/teams/administration/views/NkWeathers.vue'),
    }),
  },
  {
    path: '/administration/types',
    name: 'admin-types',
    component: NkPage,
    meta: {
      layout: 'AdminLayout',
      entry: 'admin',
      middlewares: [shouldBeLoggedMiddleware],
    },
    props: () => ({
      name: 'admin-types',
      component: () => import('@/teams/administration/views/NkTypes.vue'),
    }),
  },
  {
    path: '/administration/services',
    name: 'admin-services',
    component: NkPage,
    meta: {
      layout: 'AdminLayout',
      entry: 'admin',
      middlewares: [shouldBeLoggedMiddleware],
    },
    props: () => ({
      name: 'admin-services',
      // eslint-disable-next-line
      component: () => import('@/teams/administration/views/NkServices.vue'),
    }),
  },
  {
    path: '/administration/annotation-types',
    name: 'admin-annotation-types',
    component: NkPage,
    meta: {
      layout: 'AdminLayout',
      entry: 'admin',
      middlewares: [shouldBeLoggedMiddleware],
    },
    props: () => ({
      name: 'admin-annotation-types',
      // eslint-disable-next-line
      component: () => import('@/teams/administration/views/NkAnnotationTypes.vue'),
    }),
  },
];

export default routes;
