import { Ref, UnwrapNestedRefs } from 'vue';
import { useStore } from 'vuex';

import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'vue-query';

import { QueryFilters } from '@/types/services';
import {
  activateInvitedUser,
  createUser,
  deleteUser,
  getCurrentUser, getCurrentUserKeyFigures,
  getUser,
  getUsers,
  resetUserPassword,
  resetUserPasswordRequest,
  updateUser,
  updateUserPassword
} from '@/entities/user/services';

import {
  CreateUserData,
  DeleteUserData,
  GetUserData, GetUserKeyFiguresData,
  PasswordUserData,
  ResetUserPasswordRequestData,
  UpdateUserData,
  User,
  UpdateUserPasswordData
} from '@/entities/user/types';

import { queryKeyHash } from '@/utils/query';
import { AxiosRequestConfig } from 'axios';

const store = useStore();

export const userQueryName = 'users';

export const useGetUsersQuery = (
  options?: UseQueryOptions<User[]>,
  filters?: Ref<QueryFilters<User>>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useQuery(userQueryName, () => getUsers(filters?.value, axiosConfig), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });

export const useGetCurrentUserQuery = (options: UseQueryOptions<User> = {}) =>
  useQuery([userQueryName, 'me'], () => getCurrentUser(), {
    ...options,
  });
export const useGetCurrentUserKeyFigures = (data: Ref<GetUserKeyFiguresData>, options: UseQueryOptions<User> = {}) => useQuery([userQueryName, data], () => getCurrentUserKeyFigures(data.value), options);

export const useGetUserQuery = (data: Ref<GetUserData>, options: UseQueryOptions<User>) =>
  useQuery([userQueryName, data], () => getUser(data.value), options);

export const useCreateUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<CreateUserData>) => createUser(data), {
    onSuccess() {
      queryClient.refetchQueries(userQueryName, {active: true});
    },
  });
};

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<UpdateUserData>) => updateUser(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries(userQueryName, {active: true});
      queryClient.refetchQueries([userQueryName, { id }], {active: true});
    },
  });
};

export const useUpdateUserPasswordMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<UpdateUserPasswordData>) => updateUserPassword(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries(userQueryName, {active: true});
      queryClient.refetchQueries([userQueryName, id], {active: true});
    }
  });
}

export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: UnwrapNestedRefs<DeleteUserData>) => deleteUser(data), {
    onSuccess() {
      queryClient.refetchQueries(userQueryName, {active: true});
    },
  });
};

export const useActivateInvitedUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: PasswordUserData) => activateInvitedUser(data), {
    onSuccess() {
      queryClient.refetchQueries(userQueryName, {active: true});
    },
  });
};

export const useResetUserPasswordRequestMutation = () =>
  useMutation((data: ResetUserPasswordRequestData) => resetUserPasswordRequest(data));

export const useResetUserPasswordMutation = () =>
  useMutation((data: PasswordUserData) => resetUserPassword(data));

